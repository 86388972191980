// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./NWZ93mk1b-0.js";

const cycleOrder = ["H_dkAeKkE", "KKxJNd61y"];

const serializationHash = "framer-8C4rZ"

const variantClassNames = {H_dkAeKkE: "framer-v-9prqp8", KKxJNd61y: "framer-v-bsqzn8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {U_5FWB8bN: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "H_dkAeKkE", "Variant 2": "KKxJNd61y"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "H_dkAeKkE"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "H_dkAeKkE", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-9prqp8", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"H_dkAeKkE"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({KKxJNd61y: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8C4rZ.framer-1p2q1px, .framer-8C4rZ .framer-1p2q1px { display: block; }", ".framer-8C4rZ.framer-9prqp8 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; min-height: 29px; min-width: 30px; overflow: visible; padding: 0px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8C4rZ.framer-9prqp8 { gap: 0px; } .framer-8C4rZ.framer-9prqp8 > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-8C4rZ.framer-9prqp8 > :first-child { margin-left: 0px; } .framer-8C4rZ.framer-9prqp8 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"KKxJNd61y":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNWZ93mk1b: React.ComponentType<Props> = withCSS(Component, css, "framer-8C4rZ") as typeof Component;
export default FramerNWZ93mk1b;

FramerNWZ93mk1b.displayName = "Main-Logo";

FramerNWZ93mk1b.defaultProps = {height: 29, width: 30};

addPropertyControls(FramerNWZ93mk1b, {variant: {options: ["H_dkAeKkE", "KKxJNd61y"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerNWZ93mk1b, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})